import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Layouts from './Components/layouts/Layouts';
import MainHomePage from './Components/mainHomePage/MainHomePage';
import NotFound from './Components/NotFound/NotFound';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Layouts/>}>
          <Route index element={<MainHomePage/>}></Route>
          <Route path="*" element={<NotFound />} />
        </Route> 
      </Routes>
  );
}

export default App;
