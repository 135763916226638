import React from 'react';
import logo from '../../Images/logo.png';
// import '../header/Header.css';


const Header = () => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-white" id="navbar-eaxmple-ZTL">
                <div className="container container-fluid ">
                    <a href="#home" className="navbar-brand" >
                        <img src={logo} className=" bg-white img-fluid" style={{ width: '230px', height: '35px' }} alt="logo" border="0" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">

                        <ul className="navbar-nav" >
                            <li className="nav-item px-2">
                                <a href='#home' className="nav-link active" aria-current="page">Home</a>
                            </li>
                            <li className="nav-item px-2">
                                <a href='#about-us' className="nav-link">About Us</a>
                            </li>
                            <li className="nav-item px-2">
                                <a href='#about-us' className="nav-link">Pricing & Plan</a>
                            </li>
                            <li className="nav-item px-2">
                                <a href='#features' className="nav-link" >Features</a>
                            </li>
                            <li className="nav-item px-2">
                                <a href='#contact' className="nav-link" >Contact</a>
                            </li>
                            <li className="nav-item px-2">
                                <a href='#try-demo' className="nav-link text-white btn_request_demo" >Request Demo</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;