import React from 'react';
import WhatsUp from '../../Images/WhatsUp.png';
import Message from '../../Images/Message.png';
import ContactAlt from '../../Images/ContactAlt.png';
import ChatWithUs from '../../Images/ChatWithUs.png';


const StickySocialBar = () => {
    return (
        <> 
            <div className='icon-bar_ChatWithUs'>
                <img src={ChatWithUs} className=" img-fluid imgChatWithUs" style={{ width: '125px', height: '25px' }} alt="ChatWithUs" border="0" />
            </div>
            <div className='icon-bar'>
                <a href="#">
                    <img src={WhatsUp} className=" img-fluid rounded" style={{ width: '60px', height: '40px' }} alt="WhatsUp" border="0" />
                </a>
                <a href="#">
                    <img src={Message} className=" img-fluid rounded" style={{ width: '60px', height: '40px' }} alt="Message" border="0" />
                </a>
                <a href="#">
                    <img src={ContactAlt} className=" img-fluid rounded" style={{ width: '60px', height: '40px' }} alt="ContactAlt" border="0" />
                </a>
            </div>
        </>
    );
};

export default StickySocialBar;