import React from 'react';
import LaptopORH from '../../Images/LaptopORH.png';
import AdminWorks from '../../Images/AdminWorks.png';

const About = () => {
    return (
        <>
            <div className="row g-3 ">
                <div className="col-md-12">
                    <h3 className="header_who">Who we are</h3>
                    <h4 className="header_about">About us in short</h4>
                    <p className="text-muted about_description px-md-5">Et culpa irure occaecat enim tempor laborum.
                        Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt
                        nostrud anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing
                        incididunt nisi. Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim
                        exercitation Lorem consectetur commodo. Et culpa irure occaecat enim tempor laborum. Id anim sint
                        occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt nostrud anim
                        voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi.
                        Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim exercitation Lorem
                        consectetur commodo.
                    </p>
                </div>
                {/* <div className="col-md-6 pt-2 ">
                            <img src={LearnGirls} className=" img-fluid " style={{ width: '100%', height: '250px' }} alt="learnGirls" border="0" />
                        </div> */}
            </div>

            <div className="row g-3 mt-5" id="about-us">
                <div className="col-md-6 about_description pe-md-5">
                    <img src={LaptopORH} className=" img-fluid" style={{ width: '100%', height: '260px' }} alt="LaptopORH" border="0" />
                </div>
                <div className="col-md-6">
                    <h3 className=" header_sinewy">SINEWY Care Healthcare</h3>
                    <h3 className=" header_management">Management Software ?</h3>
                    <p className="text-muted sinewy_description">Et culpa irure occaecat enim tempor laborum.
                        Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt nostrud
                        anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi.
                        Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim exercitation
                        Lorem consectetur commodo.
                    </p>
                </div>
            </div>
            <div className="row g-3 mt-5" id="about-us">
                <div className="col-md-6 header_works_column">
                    <h3 className=" header_works">How it Works</h3>
                    <p className="text-muted works_description pe-md-5"> Id anim sint occaecat amet laboris pariatur aute ipsum.
                        Laborum eu Lorem consequat sit deserunt nostrud anim voluptate est sint sunt enim.
                        Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi. Qui amet ex elit
                        in veniam sunt. Non velit culpa occaecat nisi laborum anim anim exercitation Lorem consectetur
                        commodo.  Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit
                        deserunt nostrud anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing
                        incididunt nisi. Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim
                        exercitation Lorem consectetur commodo.
                    </p>
                </div>
                <div className="col-md-6 about_description pe-md-5">
                    <img src={AdminWorks} className=" img-fluid" style={{ width: '100%', height: '480px' }} alt="AdminWorks" border="0" />
                </div>
            </div>
        </>
    );
};

export default About;