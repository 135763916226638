import React from 'react';
import '../footer/Footer.css';
import logo from '../../Images/logo.png';
import {Link} from "react-router-dom";
import Location from '../../Images/Location.png';
import Email from '../../Images/Email.png';
import WhatsUpCircle from '../../Images/WhatsUpCircle.png';

const Footer = () => {
    return (
      <div className='footer_bg'>
        <div className="container mt-5 mb-5">
            <div className="row g-3 gx-5 pt-3 ">
                <div className="col-md-4 ">
                    <div className="header_sinewy_footer">
                        <Link to="/" className="navbar-brand" >
                            <img src={logo} className=" footer_bg img-fluid" style={{ width: '160px', height: '30px' }} alt="logo" border="0" />
                        </Link>
                    </div>
                    <p class="text-muted sinewy_footer_description pe-md-5">Et culpa irure occaecat enim tempor laborum. Id anim sint occaecat amet laboris pariatur aute ipsum. </p>
                
                    <div class="d-flex justify-content-between sinewy_footer_social_icon pe-md-5">
                        <a href="#" class="fs-4 px-2 "><i class="text-muted fab fa-facebook-f"></i></a>
                        <a href="#" class="fs-4 px-2 "><i class="text-muted fab fa-twitter"></i></a>
                        <a href="#" class="fs-4 px-2 "><i class="text-muted fab fa-instagram"></i></a>
                        <a href="#" class="fs-4 px-2 "><i class="text-muted fab fa-linkedin-in"></i></a>
                        <a href="#" class="fs-4 px-2 "><i class="text-muted fab fa-pinterest-p"></i></a>
                    </div>
                </div>
                <div className="col-md-4 ps-md-5">
                    <h3 className="header_link_footer ps-md-5">Link</h3>
                    <div className="mt-0 ps-md-5">
                        <a href="#home" className="text-muted link_footer"><p>Home</p></a>
                        <a href="#about-us" className="text-muted link_footer"><p>About us</p></a>
                        <a href="#features" className="text-muted link_footer"><p>Features</p></a>
                        <a href="#try-demo" className="text-muted link_footer"><p>Try Demo</p></a>
                        <a href="#" className="text-muted link_footer"><p>Privacy Policy</p></a>
                        <a href="#" className="text-muted link_footer"><p>Terms of Service</p></a>
                    </div>
                </div>
                <div className="col-md-4 ps-md-5">
                    <h3 className="header_contact_us_footer ps-md-5">Contact Us</h3>
                    <div className="row g-3 ps-md-5">
                        <div class="col-12">
                            <span class="d-flex align-items-start ">
                                <i class="fs-5 p-1 text-muted fas fa-map-marker-alt"></i>
                                <p class="px-3 text-muted contact_us_description ">3 Dhakeswari Road, <p className="text-wrap">Lalbagh Dhaka</p></p>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="d-flex align-items-start ">
                                <i class="fs-5 p-1 text-muted fas fa-envelope"></i>
                                <p class="px-3 text-muted contact_us_description">info@zaimahtech.com</p>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="d-flex align-items-start ">
                                <i class="fs-5 p-1 text-muted fas fa-phone-alt"></i>
                                <p class="px-3 text-muted contact_us_description">+8809617209581</p>
                            </span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <p className="footer_copyright">Copyright ©2017-2021 Zaimah Technologies Limited. All Rights Reserved.</p>
      </div>
    );
};

export default Footer;