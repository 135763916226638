import React from 'react';
import GroupHuman from '../../Images/GroupHuman.png';

const RequestDemo = () => {
    return (
        <>
            <div className="row g-3 mt-5">
                <div class="col-md-7 pe-md-5">
                    <img src={GroupHuman} className=" img-fluid" style={{ width: '100%', height: '365px', marginTop: '35px' }} alt="GroupHuman" border="0" />
                </div>
                <div className="col-md-5">
                    <h3 className="text-center header_quick_demo">Quick Demo Request</h3>
                    <p className="text-muted quick_demo_description">Let’s schedule an online demo to showcase our features, discuss your requirements or tailormade needs</p>
                    <form className="row g-3 mt-2">
                        <div className="col-md-12">
                            <input type="text" className="form-control" placeholder="First Name" aria-label="Full Name" />
                        </div>
                        <div className="col-md-12">
                            <input type="text" className="form-control" placeholder="Institute Name" aria-label="Institute name" />
                        </div>
                        <div className="col-md-12">
                            <input type="text" className="form-control" placeholder="Email Address" aria-label="Email address" />
                        </div>
                        <div className="col-md-12">
                            <input type="text" className="form-control" placeholder="Contact No." aria-label="Contact no." />
                        </div>
                        <div className="col-md-12">
                            <a src="#" type="text" className="form-control btn btn_free_trail_now">Get Your Free Trail Now !</a>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default RequestDemo;