import React from 'react';
import pppp from '../../Images/pppp.jpg';
import prr from '../../Images/prr.jpg';
import Slider_1 from '../../Images/Slider_1.png';

const Slider = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                        aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="3000">
                        <img src={Slider_1} class="d-block w-100" alt="Slider_1" />
                    </div>
                    <div class="carousel-item" data-bs-interval="3000">
                        <img src={pppp} class="d-block w-100" alt="pppp" />
                        <div class="carousel-caption d-none d-md-block">
                            <h3 class="text-danger">Second slide label</h3>
                            <p class="lead">Some representative placeholder content for the second slide.</p>
                        </div>
                    </div>
                    <div class="carousel-item" data-bs-interval="3000">
                        <img src={prr} class="d-block w-100" alt="prr" />
                        <div class="carousel-caption d-none d-md-block">
                            <h3 class="text-danger">Third slide label</h3>
                            <p class="lead">Some representative placeholder content for the third slide.</p>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </>
    );
};

export default Slider;