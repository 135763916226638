import React from 'react';
import AllUser from '../../Images/AllUser.png';
import GroupMobile from '../../Images/GroupMobile.png';

const Features = () => {
    return (
        <>
            <div className="row g-3 mt-5" >
                <div className="col-md-12">
                    <h3 className=" header_feautures">We have the Exclusive Features</h3>
                    <p className="text-muted feautures_description px-md-5">Et culpa irure occaecat enim tempor laborum.
                        Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt nostrud
                        anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi.
                        Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim exercitation Lorem consectetur commodo.
                    </p>
                </div>
            </div>
            <div className="row g-3 mt-5" >
                <div className="col-md-4">
                    <div className="row g-0">
                        <div className="col-4 all_user_card_img">
                            <img src={AllUser} className="rounded" style={{ width: '100px', height: '100px' }} alt="AllUser" />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <h6 className="card-title ">
                                    <span className="all_user_text">ALL USER</span>
                                    <span className="acc_user_text "> ACCOUNT MANAGEMENT</span>
                                </h6>
                                <p className="text-muted all_user_description">Et culpa irure occaecat enim tempor laborum.
                                    Id anim sint occaecat amet laboris pariatur aute ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row g-0">
                        <div className="col-4 all_user_card_img">
                            <img src={AllUser} className="rounded" style={{ width: '100px', height: '100px' }} alt="AllUser" />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <p className="card-title">
                                    <span className="all_user_text">APPOINTMENT</span>
                                    <span className="acc_user_text"> MANAGEMENT</span>
                                </p>
                                <p className="text-muted appointment_description">Et culpa irure occaecat enim tempor laborum.
                                    Id anim sint occaecat amet laboris pariatur aute ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row g-0">
                        <div className="col-4 all_user_card_img">
                            <img src={AllUser} className="rounded" style={{ width: '100px', height: '100px' }} alt="AllUser" />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <h6 className="card-title">
                                    <span className="all_user_text">NOTICE BOARD</span>
                                    <span className="acc_user_text"> SCHEDULE</span>
                                </h6>
                                <p className="text-muted notice_description">Et culpa irure occaecat enim tempor laborum.
                                    Id anim sint occaecat amet laboris pariatur aute ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row g-0">
                        <div className="col-4 all_user_card_img">
                            <img src={AllUser} className="rounded" style={{ width: '100px', height: '100px' }} alt="AllUser" />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <h6 className="card-title">
                                    <span className="all_user_text">MEDICATION</span>
                                    <span className="acc_user_text"> HISTORY</span>
                                </h6>
                                <p className="text-muted medication_description">Et culpa irure occaecat enim tempor laborum.
                                    Id anim sint occaecat amet laboris pariatur aute ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row g-0">
                        <div className="col-4 all_user_card_img">
                            <img src={AllUser} className="rounded" style={{ width: '100px', height: '100px' }} alt="AllUser" />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <h6 className="card-title">
                                    <span className="all_user_text">PRESCRIPTION</span>
                                    <span className="acc_user_text"> AND DIGNOSIS REPORT</span>
                                </h6>
                                <p className="text-muted prescription_description">Et culpa irure occaecat enim tempor laborum.
                                    Id anim sint occaecat amet laboris pariatur aute ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row g-0">
                        <div className="col-4 all_user_card_img">
                            <img src={AllUser} className="rounded" style={{ width: '100px', height: '100px' }} alt="AllUser" />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <h6 className="card-title">
                                    <span className="all_user_text">MEDICAL</span>
                                    <span className="acc_user_text"> REPORT MANAGEMENT</span>
                                </h6>
                                <p className="text-muted medical_description">Et culpa irure occaecat enim tempor laborum.
                                    Id anim sint occaecat amet laboris pariatur aute ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row g-0">
                        <div className="col-4 all_user_card_img">
                            <img src={AllUser} className="rounded" style={{ width: '100px', height: '100px' }} alt="AllUser" />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <h6 className="card-title">
                                    <span className="all_user_text">ALL USER</span>
                                    <span className="acc_user_text"> ACCOUNT MANAGEMENT</span>
                                </h6>
                                <p className="text-muted all_user_description">Et culpa irure occaecat enim tempor laborum.
                                    Id anim sint occaecat amet laboris pariatur aute ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row g-0">
                        <div className="col-4 all_user_card_img">
                            <img src={AllUser} className="rounded" style={{ width: '100px', height: '100px' }} alt="AllUser" />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <h6 className="card-title">
                                    <span className="all_user_text">APPOINTMENT</span>
                                    <span className="acc_user_text"> MANAGEMENT</span>
                                </h6>
                                <p className="text-muted appointment_description">Et culpa irure occaecat enim tempor laborum.
                                    Id anim sint occaecat amet laboris pariatur aute ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row g-0">
                        <div className="col-4 all_user_card_img">
                            <img src={AllUser} className="rounded" style={{ width: '100px', height: '100px' }} alt="AllUser" />
                        </div>
                        <div className="col-8">
                            <div className="card-body">
                                <h6 className="card-title">
                                    <span className="all_user_text">NOTICE BOARD </span>
                                    <span className="acc_user_text"> SCHEDULE</span>
                                </h6>
                                <p className="text-muted notice_description">Et culpa irure occaecat enim tempor laborum.
                                    Id anim sint occaecat amet laboris pariatur aute ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            {/* <div className="row g-3 mt-5">
                        <div className="col-md-12">
                            <h1 className="header_features_20"><i className="fas fa-plus fs-3"></i> 20</h1>
                            <h5 className="header_features_20_text">
                                <span>
                                    <span className="text-muted">others awesome  features in</span>
                                    <sapn className="text_color"> Learnby</sapn>
                                    <span className="text_color fs-6"> Z</span>
                                    <span className="text-muted"> School Management ERP Software</span>
                                </span>
                            </h5>
                            <a href="#" class="btn btn_see_all_feature">See All Features</a>
                        </div>
                    </div> */}



            <div class="row g-3 mt-5">
                <div className="col-md-7 header_sinewy_column">
                    <h3 className="header_sinewy">SINEWY Care</h3>
                    <p className="pt-0">
                        <span className="fs-3 header_sinewy">The Healthcare </span>
                        <sapn className="fs-4 header_mang_textColor"> Management <br /> Mobile App</sapn>
                    </p>
                    <p className="text-muted sinewy_feautures_description pe-md-5">Et culpa irure occaecat enim tempor laborum.
                        Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt nostrud anim
                        voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi. Qui amet ex elit in veniam sunt.
                        Non velit culpa occaecat nisi laborum anim anim exercitation Lorem consectetur commodo.
                    </p>
                </div>
                <div class="col-md-5 pe-md-5">
                    <img src={GroupMobile} className=" img-fluid rounded" style={{ width: '100%', height: '500px' }} alt="GroupMobile" border="0" />
                </div>
            </div>
            {/* <div class="row g-3 mt-5">
                        <div className="col-md-12">
                            <h2 className="text-muted header_main_reason">3 main reasons for explaining</h2>
                            <h5 className="text_color">why to choose us</h5>
                        </div>
                    </div>
                   <div class="row g-3 mt-5">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div class=" h-100 text-center bg-white ">
                                        <div className="mt-0">
                                            <img src={Like} class=" p-2" style={{ width: '90px', height: '85px' }} alt="Like" />
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title card_title_main_reason">The best price</h5>
                                            <p class="card-text main_reason_description">We offer the lowest possible change in the market with excellent quality work</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div class=" h-100 text-center bg-white ">
                                        <div className="">
                                            <img src={Men} class=" p-2" style={{ width: '90px', height: '85px' }} alt="Men" />
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title card_title_main_reason">Great support</h5>
                                            <p class="card-text main_reason_description">Our well-trained team provides round the clock continuous service without any kind of interruption</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div class=" h-100 text-center bg-white ">
                                        <div className="">
                                            <img src={Watch} class=" p-2" style={{ width: '90px', height: '85px' }} alt="Watch" />
                                        </div>
                                        <div class="card-body ">
                                            <h5 class="card-title card_title_main_reason">Flexibility</h5>
                                            <p class="card-text main_reason_description">learnby Z has a high level of customized School Management System</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src={reasonORH} className=" img-fluid" style={{ width: '100%', height: '285px' }} alt="reasonORH" border="0" />
                        </div>
                    </div> */}

        </>
    );
};

export default Features;