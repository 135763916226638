import React from 'react';
import {Outlet} from "react-router-dom";
import Footer from '../footer/Footer';
import Header from '../header/Header';

const Layout = ({childdren}) => {
    return (
        <>  
            <Header/>
            <div>{childdren}</div>
            <div className="content">
                <Outlet />
            </div>
            <Footer/>
        </>
    );
};

export default Layout;