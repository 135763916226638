import React from 'react';
// import '../mainHomePage/MainHomePage.css';
import Slider from '../slider/Slider';
import About from '../about/About';
import Features from '../features/Features';
import RequestDemo from '../request_demo/RequestDemo';
import Contact from '../contact/Contact';
import StickySocialBar from '../sticky_social_bar/StickySocialBar';

const MainHomePage = () => {
    return (
        <>
            <div data-bs-spy="scroll" data-bs-target="#navbar-eaxmple-ZTL" data-bs-offset="0" class="scrollspy-example" tabindex="0">{/* // see you header page */}
                <div id='home'>
                    <Slider />
                </div>
                <div className="container mb-4 mt-5">
                    <div id="about-us"  >
                        <About/>
                    </div>

                    <div>  {/* sticky social side bar  */}
                        <StickySocialBar/>
                    </div> {/* sticky social side bar  */}

                    <div id="features">
                        <Features/>
                    </div>
                    <div id="try-demo">
                        <RequestDemo/>
                    </div>
                    <div id="contact">
                        <Contact/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainHomePage;